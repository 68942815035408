import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useChat } from '../context/ChatProvider';
import useChatActions from '../hooks/useChatActions';
import useDebounce from '../hooks/useDebounce';
import { Description } from '../styled/Description';
import moment from 'moment';

const RoomListContainer = styled.div`
    --space: 1em;
    --horizontal-space: 2vw;
    
    display: flex;
    flex-direction: column;
    width: 26%;
    height: 100%;
    padding-top: var(--vertical-padding);
    overflow: auto;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    background: var( --blue-gradient);
    color: #fff;
    
    & h3 {
        font-size: 1.2em;
        font-weight: 500;
        padding: 0.9em var(--horizontal-space);
    }

    @media (max-width: 820px) {
        position: absolute;
        opacity: ${props => props.open ? '1' : '0'};
        pointer-events: ${props => props.open ? 'null' : 'none'};
        right: 0;
        width: 100%;
        border-radius: 0;
        z-index: 1;
    }
`;

const RoomItem = styled.li`
    display: flex;
    gap: 1vw;
    width: 100%;
    flex: 1;
    padding: var(--space) var(--horizontal-space);
    list-style: none;
    background: ${props => props.active ? 'var(--blue-active-color)' : 'transparent'};
    cursor: pointer;
    transition: all .05s;

    &:hover {
        background: var(--blue-active-color);
    }

    & img {
        height: 3vw;
        width: 3vw;
        border-radius: 20px;
        object-fit: cover;
    }

    & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    & span {
        font-weight: 500;
        font-size: 0.8em;
    }
     /* Conditionally apply the active class */
     &.active {
        background: var(--blue-active-color); /* Define the styles for the active class */
    }
`;


const RoomList = ({ query, isNavOpen, setIsNavOpen }) => {
  const debouncedSearch = useDebounce(query, 350);
  const { createChannel, rejoin, leaveRoom } = useChatActions();
  const { currentRoom, setCurrentRoom, userName, channel } = useChat();

  const rooms = channel.data.channels.map(ch => ({
    id: ch._id,
    name: ch.channelName,
    latestMessage: ch.latestMessage,
    description: '',
    src: 'https://muktinathkrishi.com/assets/img/mkm-logo.gif',
    users: ch.users
  }));
  const filteredRooms = useMemo(() => {

    const filter = rooms.filter(room => {
      const includesCaseInsensitive = {
        name: room.name.toLowerCase(),
        description: room.description.toLowerCase()
      };

      const { name, description } = includesCaseInsensitive;

      return name.includes(debouncedSearch.toLowerCase()) || description.includes(debouncedSearch.toLowerCase());
    });

    return filter;
  }, [debouncedSearch]);

  const handleRoomClick = (roomID) => {
    if (currentRoom?.id === roomID) {
      return;
    }

    const selectedRoom = channel.data.channels.find(room => room._id === roomID);
    if (selectedRoom && selectedRoom._id) {
      console.log(selectedRoom._id,"leave room")
      leaveRoom(selectedRoom._id);

    }
    setCurrentRoom(selectedRoom);
    // rejoin();
    createChannel(selectedRoom);


    setIsNavOpen(false);
  }


  return (
    <RoomListContainer open={isNavOpen}>
      <h3>Rooms</h3>
      <ul>
        {

          filteredRooms.map(room => {
            const { id, name, src, latestMessage, users } = room;
            console.log(id, currentRoom);
            return (
              <RoomItem active={currentRoom?._id === id} key={id} onClick={() => handleRoomClick(id)} >
                <img alt='room-img' src={src} />

                <div>
                  <span>{name}</span>
                  <Description color='rgba(254,254,254,0.5)' size='1em'>{latestMessage && latestMessage.message}</Description>
                  <p>by {latestMessage && users.find(u => u.id === latestMessage.senderId)&& users.find(u => u.id === latestMessage.senderId).name}</p>
                  <p className="time">{latestMessage && moment(latestMessage.createdAt).fromNow()}</p>

                </div>
              </RoomItem>
            );
          })
        }
      </ul>
    </RoomListContainer>
  );
};

export default RoomList;