import { getToken } from "../util/getToken";

export function getMessageFromChannel(channelId) {
  const myHeaders = new Headers();
  const token = getToken();
  myHeaders.append("Authorization", "Bearer "+token);
  const baseUrl = process.env.REACT_APP_SERVER || window.location.origin;
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(baseUrl+"/api/chat/messages?channelId="+channelId, requestOptions);
}