import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getFirstLetter } from '../helpers';
import useMessages from '../hooks/useMessages';
import { useChat } from '../context/ChatProvider';
import { getMessageFromChannel } from '../api/messagesapi';
import moment from 'moment';

const ConversationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1vh;
    flex: 1;
    padding: 20px;
    overflow: auto;
`;

const MessageContent = styled.div`
    display: flex;
    font-size: 1em;
    font-weight: 300;
    padding: 0.8em 1em;
    width: fit-content;
    height: fit-content;
`;

const MessageContainer = styled.div`
    display: flex;
    gap: 20px;
    color: #fff;
    font-size: 1rem;
    flex-direction: ${props => props.incomingMessage ? 'row' : 'row-reverse'};

    ${MessageContent} {
        background: ${props => props.incomingMessage ? 'var(--blue-gradient)' : '#fff'};
        border: ${props => props.incomingMessage ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'};
        color: ${props => props.incomingMessage ? '#fff' : '#000'};
        box-shadow:  ${props => props.incomingMessage ? 'rgba(32, 112, 198, 0.4)' : 'rgba(0, 0, 0, 0.15)'} 2px 3px 15px;
        border-radius: ${props => props.incomingMessage ? '0 8px 8px 8px' : '8px 0 8px 8px'};
    }
`;

const UserProfile = styled.div`
    display: flex;
    flex-direction: column; /* Add a new line */
    align-items: center; /* Center items vertically */
    position: relative;
    height: 100%;

    &::before {
        content: '${props => getFirstLetter(props.content)}';
        display: grid;
        place-content: center;
        padding: 0.5em;
        width: 1.3em;
        height: 1.3em;
        border-radius: 50%;
        background: var(--secondry-color-dark-palette);
    }

    .name {
        font-size: 12px; 
        color:black;
        padding:10px
    }
    .time {
        font-size: 10px;
        color: gray;
    }
`;
const BotMessage = styled.div`
    width: fit-content;
    margin: 0 auto;
    padding: 0.85em 1.7em;
    font-size: 0.7em;
    text-align: center;
    border-radius: 2em;
    background: rgba(0,0,0,0.05);
`;

const Conversation = () => {
  const { socket, socketUserId, currentRoom } = useChat();
  const messages = useMessages();
  const chatConversation = useRef(null);

  // auto scroll to bottom on new message recieve / sent
  useEffect(() => {
    chatConversation.current.scrollTo(0, chatConversation.current.scrollHeight);
  }, [messages]);

  return (
    <ConversationContainer ref={chatConversation}>
      {
        messages.map((m) => {
          const { message, senderId, channelId, _id ,createdAt} = m;

          const isBot = (senderId === 'BOT' && !channelId);
          const sendPerson = currentRoom.users.find((u) => u.id === senderId);
          console.log(sendPerson);
          if(sendPerson){
            return isBot ?
            <BotMessage> {message} </BotMessage>
            :
            (
              <MessageContainer key={_id} incomingMessage={senderId !== socketUserId}>
                <UserProfile content={sendPerson.name} isAdmin={sendPerson.type==="Admin"} >    
                <div className="name">{sendPerson.name}
                <p className="time">{moment(createdAt).fromNow()}</p>

                </div>
                </UserProfile>
                <MessageContent><TextWithLineBreaks text={message} /></MessageContent>
              </MessageContainer>
            );
          }
        })
      }
    </ConversationContainer>
  );
};
function TextWithLineBreaks({ text }) {
  const lines = text.split('\n');

  return (
    <div>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < lines.length - 1 && <br />} {/* Add <br> except for the last line */}
        </React.Fragment>
      ))}
    </div>
  );
}
export default Conversation;