import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { getToken } from '../util/getToken';
import { getChannelList } from '../api/channelapi';

const ChatContext = createContext();

export const useChat = () => {
  return useContext(ChatContext);
}

export const ChatProvider = ({ children }) => {
  const [userName, setUserName] = useState(null);
  const [socketUserId, setSocketUserId] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [socket, setSocket] = useState(null);
  const [channel,setChannel] = useState(null);
  console.log(currentRoom,"currentRoom");
  useEffect(() => {

    getChannelList().then(response => response.json())
    .then(response => setChannel(response));

    const token = getToken();
    const serverUrl = process.env.REACT_APP_SERVER || window.location.origin;
    const socket = io.connect(serverUrl, {
      query: {
        token: token
      },
      // withCredentials: true,

      reconnection: true,
    });

    // Handle socket connection events
    socket.on('connect', () => {
      console.log('Socket connected');
    });
     socket.on('channelCreated', channelObj => {
      console.log(channelObj,"channelCreated");
    });
     socket.on('currentUser', currentUser => {
      console.log(currentUser,"currentUser");

      setSocketUserId(currentUser);
      setUserName(currentUser.name);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });
    

    setSocket(socket);

    
    // Clean up socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);
  const value = {
    socket,
    userName,
    setUserName,
    setCurrentRoom,
    currentRoom,
    channel,
    socketUserId
  };

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;



