import React, { useEffect, useState } from "react";
import { useChat } from "../context/ChatProvider";
import { getMessageFromChannel } from "../api/messagesapi";

const useMessages = () => {
  const { socket, currentRoom } = useChat();
  console.log("rooom ",currentRoom)
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    getMessageFromChannel(currentRoom._id)
      .then(response => response.json())
      .then(response => setMessages(response.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))));
  }, []);
  useEffect(() => {

    console.log(messages, "messages");

    socket.on(`message`, (newMessage) => {
      console.log(newMessage);
      setMessages((m) => [...m, newMessage]);
    });
   
    socket.on(`messageError`, (newMessage) => {
      console.log(newMessage);
    });

    return () => {
      socket.off('message');
    }
  }, [ socket]);

  useEffect(() => {
    setMessages([]);
    getMessageFromChannel(currentRoom._id)
    .then(response => response.json())
    .then(response => setMessages(response.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))));
  }, [currentRoom])

  return messages;
}

export default useMessages;
