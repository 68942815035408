import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { ChatProvider } from './context/ChatProvider';
import Wrapper from './components/Wrapper';

const GlobalStyle = createGlobalStyle`
  :root {
    --main-color-dark-palette: #1a1a1a;
    --secondry-color-dark-palette: #373737;
    --blue-button-color: #3c95f4;
    --blue-active-color: #2070c6;
    --green-gradient: linear-gradient(90deg, #17AF4E 65%, #17AF4E 100%);
    --blue-gradient: linear-gradient(90deg, #3c95f4 65%, #3385dc 100%);
  }

  * {
    margin: 0;
    padding: 0;
    outline: transparent;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background: #17AF4E;
  }
`;

const Background = styled.div`
position: absolute;
height: 100vh;
width: 100vw;
overflow: hidden;
z-index: -1;

&::before, &::after {
    content: '';
    position: absolute;
    inset: -170px auto auto -200px;
    width: clamp(30vw, 600px, 42vw);
    height: clamp(30vw, 600px, 42vw);
    border-radius: 50%;
    background: #1e6dbf;
    z-index: -1;
  }

  &::after {
    inset: auto -170px -200px auto;
  }

  @media (max-width: 820px) {
    &::before, &::after {
      width: 25rem;
      height: 25rem;
    }
  }
`;

function App() {
  const [token,setToken] =useState(null);
  useEffect(() => {
    if( localStorage.getItem('token')){
      setToken(localStorage.getItem('token'));
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const tokenData = urlParams.get('token');

    if (tokenData) {
      // Save the token to local storage
      localStorage.setItem('token', tokenData);

      // Remove the token from the query parameters
      urlParams.delete('token');

      // Replace the current URL with the new search string
      const newUrl = `${window.location.origin}${window.location.pathname}`;
      console.log("here");
      window.location.replace(newUrl);
      setToken(tokenData);
    }
  }, []);




  return (
   token ? <>
   <GlobalStyle />
   
   <Background />

   <ChatProvider>  
     <Wrapper />
   </ChatProvider>
 </>:<p>No token provided</p>
  );
}

export default App;
