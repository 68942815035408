import { getToken } from "../util/getToken";

export async function getChannelList() {
  const token = getToken();
  const baseUrl = process.env.REACT_APP_SERVER || window.location.origin;
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer "+token);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(baseUrl+"/api/chat/channel", requestOptions);
}