import { useChat } from "../context/ChatProvider";

const useChatActions = () => {
  const { socket, currentRoom} = useChat();
  const rejoin = () => {
    socket.emit('rejoined');
  }
  const createChannel = (channel) => {
    socket.emit('createChannel',channel);
  }

  const leaveRoom = (roomID) => {
    socket.emit('disconnectChannel', roomID);
  }

  const sendMessage = (text, channelId) => {
    if (!text) {
      return;
    }
    socket.emit('sendMessage', { message: text, channelId, "messageType": "simple" });
  }

  return {
    rejoin,
    createChannel,
    sendMessage,
    leaveRoom
  }
};

export default useChatActions;